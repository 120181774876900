<template>
      <v-container fluid class="ma-0 pa-0 clear-background" >
        <div class="max-width-1200 ma-auto">
            <v-layout row wrap pb-12>
            <v-flex sm12 >
                <v-layout>
                    <v-flex sm6 offset-xs1 mt-12 mr-12>
                      <p class="venera font-19 primary-color mb-0">{{$ml.get('since')}} 2010</p>
                      <p class="antenna font-48 primary-color ma-0"> {{ $ml.get('portfolio_title') }} </p>
                      <p class="avenir font-18 primary-color"> {{ $ml.get('portfolio_text') }} </p>
                    </v-flex>
                </v-layout>
            </v-flex>


            <v-flex xs12 sm10 offset-sm1>
                    <p class="venera font-19 primary-color mt-12 text-center">  {{ $ml.get('portfolio_subtitle') }}</p>
                    <v-layout wrap pa-4>
                    <v-flex v-for="(company, i) in companies" :key="i" xs6 sm4 child-flex pa-2>
                           
                            <div class="image-container" v-bind:class="{ hover: company.url }"  @click="openLink(company.url)">
                              <v-img :src="company.background" :lazy-src="company.background" aspect-ratio="1" class="grey lighten-2 portfolio-image blue-overlay" >
                                <template v-slot:placeholder>
                                    <v-layout fill-height align-center justify-center ma-0>
                                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                    </v-layout>
                                </template>
                            </v-img>
                              <div class="centered"><img :src="company.logo" :lazy-src="company.logo" class="my-4 logo-width"/></div>
                              
                            </div>
                    </v-flex>
                    </v-layout>
            </v-flex>



            </v-layout>
        </div>
      </v-container>
</template>

<script>
export default {
  data () {
    return {
      companies: [
        {
          background: require('../assets/img/companies/gallo.png'),
          logo: require('../assets/img/logos/gallo.png'),
          url: 'https://www.galloazul.mx/',
        },
        {
          background: require('../assets/img/companies/gorila.png'),
          logo: require('../assets/img/logos/gorila.png'),
          url: 'https://www.gorilaseguros.com/'
        },
        {
          background: require('../assets/img/companies/ioc.png'),
          logo: require('../assets/img/logos/ioc.png')
        },
        {
          background: require('../assets/img/companies/conttento.png'),
          logo: require('../assets/img/logos/conttento.png')
        },
        {
          background: require('../assets/img/companies/syma.png'),
          logo: require('../assets/img/logos/syma.png'),
          url: 'https://www.syma.mx/'
        },
        {
          background: require('../assets/img/companies/leven.png'),
          logo: require('../assets/img/logos/leven.png'),
          url: 'https://www.levenpc.com/'
        },
        {
          background: require('../assets/img/companies/niklaas.png'),
          logo: require('../assets/img/logos/niklaas.png'),
          url: 'https://niklaasfritzwatches.com/'
        },
        {
          background: require('../assets/img/companies/opengg.png'),
          logo: require('../assets/img/logos/opengg.png'),
          url: 'https://www.theopen.gg/'
        },
        {
          background: require('../assets/img/companies/hackbrake.png'),
          logo: require('../assets/img/logos/hackbrake.png'),
          url: 'https://www.hackbrake.com.mx/'
        },
        {
          background: require('../assets/img/companies/zafira.png'),
          logo: require('../assets/img/logos/zafira.png')
        },
        {
          background: require('../assets/img/companies/shootvenirs.png'),
          logo: require('../assets/img/logos/shootvenirs.png'),
          url: 'https://www.shootvenirs.com/'
        },
        {
          background: require('../assets/img/companies/vinologia.png'),
          logo: require('../assets/img/logos/vinologia.png'),
          url: 'https://www.vinologia.mx/'
        },
        {
          background: require('../assets/img/companies/pintart.png'),
          logo: require('../assets/img/logos/pintart.png'),
          url: 'https://www.pintartgallery.com/'
        },
        {
          background: require('../assets/img/companies/empowermind.png'),
          logo: require('../assets/img/logos/empowermind.png')
        },
        {
          background: require('../assets/img/companies/cbd.png'),
          logo: require('../assets/img/logos/cbd.png'),
          url: 'https://www.cbdorganics.mx/'
        },
        {
          background: require('../assets/img/companies/buscarea.png'),
          logo: require('../assets/img/logos/buscarea.png')
        },
        {
          background: require('../assets/img/companies/inverteca.png'),
          logo: require('../assets/img/logos/inverteca.png'),
          url: 'https://www.inverteca.mx/'
        },
        {
          background: require('../assets/img/companies/lmt.png'),
          logo: require('../assets/img/logos/lmt.png')
        }
      ],
    }
  },
  methods: {
    openLink (url) {
      if (url) {
        window.open(url, '_blank')
      }
    }
  }
}
</script>

<style>

.portfolio-image {
  height: 400px !important;
  filter: brightness(0.8);
}

.logo-width {
  width: 250px !important;
}

.blue-overlay {
    outline: 200px solid rgba(26,60,79,0.4) !important;
    outline-offset: -200px !important;
    overflow: hidden !important;
    position: relative !important;
    width: 100% !important;
}

@media screen and (max-width: 600px) {
  .logo-width {
    width: 150px !important;
} 
}


</style>
